import React, { useEffect, useState } from 'react'
import Select from '../../components/Select'
import { withLoadingAsync, showToast, showModal } from '../../services/common-service'
import { setMessageTemplateAsync, getMessageTemplatesAsync } from '../../services/waba-service'
import BlipPortalToastTypes from '../../constants/blip-portal-toast-types'

const Home = () => {
    const [templateVariables, setTemplateVariables] = useState([])
    const [templateVariablesContent, setTemplateVariablesContent] = useState([])
    const [templateButtons, setTemplateButtons] = useState([])
    const [templateButtonsErrors, setTemplateButtonsErrors] = useState({ "0": false, "1": false, "2": false, })
    const [templateType, setTemplateType] = useState("")
    const [templateContentType, setTemplateContentType] = useState("Text")
    const [useCta, setUseCta] = useState("Disabled")
    const [useHeader, setUseHeader] = useState(false)
    const [useFooter, setUseFooter] = useState(false)
    const [ctaType, setCtaType] = useState("URL")
    const [ctaText, setCtaText] = useState("")
    const [ctaValue, setCtaValue] = useState("")
    const [templateName, setTemplateName] = useState("")
    const [templateHeader, setTemplateHeader] = useState("")
    const [templateBody, setTemplateBody] = useState("")
    const [templatefooter, setTemplateFooter] = useState("")
    const [isNameError, setIsNameError] = useState(false)
    const [templateList, setTemplatelist] = useState([])

    useEffect(async () => {
        setTemplatelist(await getMessageTemplatesAsync())
        // eslint-disable-next-line
    }, []);

    const templateTypeOptions = [
        { value: 'MARKETING', label: 'Marketing' },
        { value: 'TRANSACTIONAL', label: 'Transacional' },
        { value: 'OTP', label: 'Senhas Descartáveis' }
    ]

    const templateContentTypeOptions = [
        { value: 'Text', label: 'Texto' },
        { value: 'Media', label: 'Midia' },
        { value: 'Document', label: 'Documento' }
    ]

    const callToActionOptions = [
        { value: 'Disabled', label: 'Sem Call To Action' },
        { value: 'CTA', label: 'Utilizar Call To Action' },
        { value: 'Buttons', label: 'Utilizar Quick Reply' }
    ]

    const callToActionTypes = [
        { value: 'BUTTONS', label: 'Telefone' },
        { value: 'URL', label: 'Website' }
    ]

    const isValidTemplateName = (newValue) => {
        const regex = /^[a-z]([a-z0-9_])*$/
        const maxLength = 512
        return regex.test(newValue) && newValue.length <= maxLength
    }

    const templateNameChange = (event) => {
        const newValue = event.target.value
        if (isValidTemplateName(newValue)) {
            setTemplateName(event.target.value)
            setIsNameError(false)
        } else {
            setIsNameError(true)
        }
    }

    const findTemplateVariables = () => {
        const regex = new RegExp("{{\\d+}}", 'g')
        const matches = templateBody.match(regex)
        setTemplateVariables(matches)
    }

    const templateReplaced = () => {
        let newtext = templateBody

        if (templateVariables) {
            templateVariables.map((variable, index) => {
                if (templateVariablesContent[index] !== "" && templateVariablesContent[index]) {
                    newtext = newtext.replace(variable, templateVariablesContent[index])
                }
                return newtext
            })
        }

        return newtext
    }

    const createTemplate = () => {
        const msgTemplate = {
            "name": templateName,
            "components": [],
            "category": templateType,
            "language": 'pt_BR',
        }
        if (useHeader) {
            msgTemplate["components"].push(
                {
                    "type": "HEADER",
                    "format": "TEXT",
                    "text": templateHeader
                }
            )
        }
        if (templateBody !== "") {
            msgTemplate["components"].push(
                {
                    "type": "BODY",
                    "text": templateBody
                }
            )
        }
        if (useFooter) {
            msgTemplate["components"].push(
                {
                    "type": "FOOTER",
                    "text": templatefooter
                }
            )
        }
        if (useCta === "Buttons" && templateButtons.length > 0) {
            msgTemplate["components"].push(
                {
                    "type": "BUTTONS",
                    "buttons": templateButtons.map((str) => {
                        return {
                            type: "QUICK_REPLY",
                            text: str,
                        };
                    })
                }
            )
        }
        if (useCta === "CTA" && ctaType === "URL") {
            msgTemplate["components"].push(
                {
                    "type": "URL",
                    "text": ctaText,
                    "url": ctaValue
                }
            )
        }
        if (useCta === "CTA" && ctaType === "BUTTONS") {
            msgTemplate["components"].push(
                {
                    "type": "BUTTONS",
                    "buttons": [
                        {
                            "type": "PHONE_NUMBER",
                            "text": ctaText,
                            "phone_number": ctaValue
                        }
                    ]
                }
            )
        }
        if (msgTemplate["components"].length === 0) {
            delete msgTemplate["components"]
        }
        console.log(msgTemplate)
        return (msgTemplate)
    }

    return (
        <div className="ph1 ph4-m ph5-ns pb5 mh6">
            <div className="mt4">
                <div className='flex justify-between items-center mh3  bb bb-bc-neutral-medium-wave'>
                    <h2 style={{ color: "#6E7B91" }}>Template Studio</h2>
                    <div className='flex'>
                        <div className="mr3">
                            <bds-button
                                variant='tertiary'
                                icon='refresh'
                                onClick={() => {
                                }}
                            >
                                Recomeçar
                            </bds-button>
                        </div>
                        <bds-button
                            variant='primary'
                            arrow="true"
                            onClick={async () => {
                                if (templateName !== "" && templateType !== "") {

                                    if (templateList.data.some(obj => obj.name === templateName)) {
                                        showToast({
                                            type: BlipPortalToastTypes.DANGER,
                                            message: "Esse nome de template já está sendo usado"
                                        })
                                    } else {
                                        await withLoadingAsync(async () => {
                                            await setMessageTemplateAsync(createTemplate())
                                            showToast({
                                                type: BlipPortalToastTypes.SUCCESS,
                                                message: "Template submetido para análise com sucesso"
                                            })
                                        })
                                    }
                                } else {
                                    showToast({
                                        type: BlipPortalToastTypes.DANGER,
                                        message: "Preencha todos os campos para submeter o template"
                                    })
                                }
                            }}
                        >
                            Submeter
                        </bds-button>
                    </div>
                </div>
                <div className="flex justify-center mt3 mh3">
                    <div className="w-33 mr5">
                        <bds-input
                            label="Nome do template"
                            danger={isNameError}
                            error-message="Use letras minúsculas, números ou underlines, começando com uma letra."
                            value={templateName}
                            onInput={templateNameChange}
                        />
                    </div>
                    <div className="w-33 mr5">
                        <Select
                            label="Categoria do template"
                            value={templateType}
                            options={templateTypeOptions}
                            onChange={(e) =>
                                setTemplateType(e.target.value)
                            }
                        />
                    </div>
                    <div className="w-33">
                        <Select
                            label="Tipo do conteudo"
                            value={templateContentType}
                            options={templateContentTypeOptions}
                            onChange={(e) =>
                                setTemplateContentType(e.target.value)
                            }
                        />
                    </div>
                </div>
                <div>
                    <div className="flex">
                        <div className='w-50 pa3'>
                            {useHeader &&
                                <>
                                    <div className='mb2'>
                                        <bds-paper elevation="static">
                                            <bds-input
                                                label="Header"
                                                value={templateHeader}
                                                is-textarea
                                                rows="2"
                                                expand="true"
                                                onInput={(e) => {
                                                    setTemplateHeader(e.target.value)
                                                }}
                                            />
                                        </bds-paper>
                                    </div>
                                </>
                            }
                            <div className='mb2'>
                                <bds-paper elevation="static">
                                    <bds-input
                                        label="Body"
                                        value={templateBody}
                                        is-textarea
                                        rows="16"
                                        expand="true"
                                        onInput={(e) => {
                                            setTemplateBody(e.target.value)
                                        }}
                                    />
                                </bds-paper>
                            </div>
                            {useFooter &&
                                <>
                                    <div className=''>
                                        <bds-paper elevation="static">
                                            <bds-input
                                                label="Footer"
                                                value={templatefooter}
                                                is-textarea
                                                rows="2"
                                                expand="true"
                                                onInput={(e) => {
                                                    setTemplateFooter(e.target.value)
                                                }}
                                            />
                                        </bds-paper>
                                    </div>
                                </>
                            }
                        </div>
                        <div className='w-50 pa3'>
                            <div className='pb2'>
                                <Select
                                    label="CTA e Botões"
                                    value={useCta}
                                    options={callToActionOptions}
                                    onChange={(e) =>
                                        setUseCta(e.target.value)
                                    }
                                />
                            </div>
                            {useCta === "Buttons" &&
                                <>
                                    <div className='pb2 flex justify-center'>
                                        <bds-button
                                            variant='tertiary'
                                            icon='plus'
                                            onClick={() => {
                                                if (templateButtons.length < 3) {
                                                    const newTemplateButtons = [...templateButtons]
                                                    newTemplateButtons.push("")
                                                    setTemplateButtons(newTemplateButtons)
                                                }
                                            }}
                                        >
                                            Novo Botão
                                        </bds-button>
                                    </div>

                                    {templateButtons?.map((item, index) => (
                                        <div key={index} className="pb2 flex items-center">
                                            <bds-input
                                                label={"Botão " + (index + 1)}
                                                value={templateButtons[index]}
                                                danger={templateButtonsErrors[index]}
                                                onInput={(e) => {
                                                    const newButtonsErrors = templateButtonsErrors
                                                    newButtonsErrors[index] = e.target.value.length <= 20 ? false : true
                                                    setTemplateButtonsErrors(newButtonsErrors)

                                                    const newTemplateButtons = [...templateButtons]
                                                    newTemplateButtons[index] = e.target.value.slice(0, 20)
                                                    setTemplateButtons(newTemplateButtons)
                                                }}
                                            />
                                            <bds-button-icon
                                                icon="trash"
                                                variant="secondary"
                                                size="short"
                                                onClick={() => {
                                                    const newTemplateButtons = [...templateButtons]
                                                    newTemplateButtons.splice(index, 1)
                                                    setTemplateButtons(newTemplateButtons)

                                                    const newButtonsErrors = templateButtonsErrors
                                                    newButtonsErrors[index] = false
                                                    setTemplateButtonsErrors(newButtonsErrors)
                                                }}
                                            />
                                        </div>
                                    ))}
                                </>
                            }
                            {useCta === "CTA" &&
                                <>
                                    <div className='pb2'>
                                        <Select
                                            label="Tipo de CTA"
                                            value={ctaType}
                                            options={callToActionTypes}
                                            onChange={(e) =>
                                                setCtaType(e.target.value)
                                            }
                                        />
                                    </div>
                                    {ctaType !== "" &&
                                        <>
                                            <div className='pb2'>
                                                <bds-input
                                                    label={"Texto do Botão"}
                                                    value={ctaText}
                                                    onInput={(e) => {
                                                        setCtaText(e.target.value)
                                                    }}
                                                />
                                            </div>
                                            <div className='pb2'>
                                                <bds-input
                                                    label={ctaType === "URL" ? "Url do website" : "Número do telefone"}
                                                    value={ctaValue}
                                                    onInput={(e) => {
                                                        setCtaValue(e.target.value)
                                                    }}
                                                />
                                            </div>
                                        </>
                                    }
                                </>
                            }
                            <div className="flex mt3 pt2 bt bt-bc-neutral-medium-wave">
                                <div className=''>
                                    <bds-typo variant="fs-12" bold='bold'>Utilizar Header</bds-typo>
                                    <div className="flex items-center">
                                        <div className='mr2'>
                                            <bds-switch
                                                id="switch-2"
                                                onClick={(e) => {
                                                    setUseHeader(e.target.checked)
                                                }}
                                                name="string"
                                                disabled="false"
                                                checked="false"
                                            />
                                        </div>
                                        <bds-typo variant="fs-12">{useHeader ? "Sim" : "Não"}</bds-typo>
                                    </div>
                                </div>
                                <div className='pl3'>
                                    <bds-typo variant="fs-12" bold='bold'>Utilizar Footer</bds-typo>
                                    <div className="flex items-center">
                                        <div className='mr2'>
                                            <bds-switch
                                                id="switch-2"
                                                onClick={(e) => {
                                                    setUseFooter(e.target.checked)
                                                }}
                                                name="string"
                                                disabled="false"
                                                checked="false"
                                            />
                                        </div>
                                        <bds-typo variant="fs-12">{useFooter ? "Sim" : "Não"}</bds-typo>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    );
};

Home.propTypes = {};

export default Home;
